import React, { Component } from 'react'
import { Link } from "react-router-dom";

import client from "../../client"
import imageUrlBuilder from '@sanity/image-url'

import "./Opportunities.css"
import "../Header/Header.css"

import ArticleCard from '../ArticleCard/ArticleCard'
import Header from '../Header/Header'

/**
 *  Opportunities page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Journal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Store fetched categories and posts data
            posts: [],
            reloadKey: Math.random(),
            isLoading: true
        }

        this.urlFor = this.urlFor.bind(this)
    }

    /**
     * Reload (Re-render page) component by updating reload key
     */
    reload = () => {
        this.setState({
                reloadKey: Math.random(),
                isLoading: true
            }
        )
        
      } 

    componentDidMount() {
        this.getPosts()
        
    }

    componentDidUpdate(prevProps, prevState) {
        // Listens for url query change and update post list based on the chosen category
        if (prevState.reloadKey !== this.state.reloadKey) {
            this.getPosts()
          }

    }

    /**
     * Fetch posts drom Sanity data store
     * @see {@link https://www.sanity.io/docs/js-client} for more information
     */
    getPosts = () => {

        let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'

        client.fetch(`*[_type == "post" && category._ref == "${opportunitiesID}"] | order(publishedAt desc)
        {_id, title, body, slug, mainImage, publishedAt, textPreview, "category": category->title, "author": author->name, "authorImage": author->image}`)
            .then(data => this.setState({posts: data}))
            .then(() => this.setState({isLoading: false}))
            .catch(err => console.log(err))
    }

   
    /**
     * Generate image urls from Sanity image records.
     * @param {string} source 
     * @see {@link https://www.sanity.io/docs/image-url} for more information
     */
    urlFor(source) {
        return imageUrlBuilder(client).image(source)
    }


    render() {
        console.log(this.state.posts)
        let articles = this.state.posts
        let loaderStyle = {
                // position: 'absolute',
                display: 'block',
                width: '100%',
                height: '100vh',
                padding: '10%',
                margin: 'auto',
                backgroundColor: 'rgba(255,255,255,0.95)',
                zIndex: '999',
                textAlign: 'center',
                letterSpacing: '0.05em',
                color: '#52557a'
            }

        if(!this.state.isLoading) {
            loaderStyle = {display: 'none'}
        }

        return(
            <div>
                <Header 
                        pageName="Opportunities" 
                        bgColor="linear-gradient(170deg, #9f44d2, #44abd2) 0% 0% / 300% 300%" 
                        bgImage="audio-1844798_1920.jpg"
                        title="Opportunities"
                        content={<p style={{fontSize: '1.1em'}}>The latest opportunities from Sleigh Group</p>}
                        flexDirection="column"
                        marginTop="50px"
                        align="center"
                    />
                <div className="main-container" id="opportunities-container">
                        
                    <div className="main-content">
                        
                
                            <div className="loader" style={loaderStyle}><h3>Loading posts...</h3></div>
                            <div className="opportunities-collections" style={{marginTop: "80px"}}>
                                {/* Loop through articles and render previews of the articles*/}
                                {articles.map((article, i) => {
                                    let image, flexDirection, authorImage;

                                    // Change flex directio on smaller screes
                                    if(window.innerWidth > 768) {
                                        if(i % 2 === 0) {
                                            flexDirection = 'row-reverse'
                                        } else {
                                            flexDirection = 'row'
                                        }
                                    } else {
                                        flexDirection = 'column'
                                    }

                                    
                                    // Make sure the article data and repective images exist to avoid undefined errors before setting variables
                                    if(article.mainImage !== undefined) {
                                        image = this.urlFor(article.mainImage.asset._ref);
                                    } else {
                                        image = ""
                                    }

                                    if(article.authorImage !== undefined) {
                                        authorImage = this.urlFor(article.authorImage.asset._ref);
                                    } else {
                                        authorImage = ""
                                    }

                                    return(
                                        
                                        <div key={article._id}>
                                            <Link to= {`/opportunities/${article.slug.current}`} >
                                                <ArticleCard 
                                                    postType = "opportunities"
                                                    title={article.title}
                                                    subtitle={article.category}
                                                    backgroundImage={image}
                                                    direction= {flexDirection}
                                                    date={new Date(article.publishedAt).toDateString()}
                                                    content={article.textPreview}
                                                    author={article.author}
                                                    authorImage={authorImage}
                                                />
                                            </Link>
                                        </div>
                                        
                                    ) 
                                })}
                                
                            </div>
                                
                    </div>
                    
                </div>
            </div>
        )
    }
}