import React, { Component } from 'react' 
import { NavLink, Link } from 'react-router-dom';

import Button from '../Button/Button'
// import HamburgerIcon from '../HamburgerIcon/HamburgerIcon'

import './Navbar.css'

export default class Navbar extends Component {
    render() {
        return(
            <div id="navbar">
              <div id="logo-wrapper">
                <NavLink to="/">
                  <img id="logo" src={require("../../assets/logo/SleighLogo-Black.png")} alt="Sleigh Group" />
                </NavLink>
              </div>
              
              <nav id="main-nav">
                <ul>
                  <li className="home-nav-item">
                    <NavLink exact to={"/"} activeClassName="active-link">Home</NavLink>
                  </li>
                  <li className="about-nav-item">
                    <NavLink to={"/about-us"} activeClassName="active-link">About us</NavLink>
                  </li>
                  <li className="services-nav-item">
                    <NavLink to={"/services"} activeClassName="active-link">Services</NavLink>
                  </li>
                  <li className="journal-nav-item">
                    <NavLink to={"/journal"} activeClassName="active-link">Journal</NavLink>
                  </li>
                  <li className="opportunities-nav-item">
                    <NavLink to={"/opportunities"} activeClassName="active-link">Opportunities</NavLink>
                  </li>
                </ul>
              </nav>
              <div className="inquire-btn btn-container"> 
                <Link to="/inquire">
                  <Button buttonClass={"main-button"} buttonText={"Inquire"}></Button>
                </Link>
              </div>
              {/* <div className="login-btn btn-container" onClick={this.props.loginClickHandler}>
                <Button buttonClass={"light-button"} buttonText={"Login"}></Button>
              </div> */}
              
            </div>
        )
    }
}