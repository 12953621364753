import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import './Header.css'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

/**
 *  Header component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */
export default class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // Scale of the background and header texts that will be updated on scroll for micro-interactions
            backgroundTransform: 'scale(1)',
            textTrasform: 'scale(1)'
        }
        this.handleScroll = this.handleScroll.bind(this)

    }

    componentDidMount() {
        // Listens for scrolling...
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     *  Trigger micro-interactions on scroll
     */
    handleScroll() {
        let scrollTop = document.documentElement.scrollTop
        let scaleVal, scaleDown;

        // Zooms background on scroll
        // 100 indicates the full width and height of 100%
        scaleVal = (100 + (scrollTop / 50)) / 100

        // To prevent the text inside the header to grow larger on scroll, scale down the text relative to the scale of the background
        scaleDown = 1 - (scaleVal - 1)

        // Update state with scale value
        this.setState({
            backgroundTransform: `scale(${scaleVal})`,
            textTrasform: `scale(${scaleDown})`,
        });
    }

    render() {
        return (
            <div className="header-container" style={{ background: `url(${require(`../../assets/${this.props.bgImage}`)})`, transform: this.state.backgroundTransform }}>
                <div className="header-overlay" style={{ background: this.props.bgColor }}>

                    <div className="breadcrumbs" style={{ transform: this.state.textTrasform }}>
                        <Link to="/" style={{ textDecoration: 'none', fontWeight: 'bold', color: "white" }}>Home</Link> / {this.props.pageName}
                    </div>
                    
                    <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                        <div className="header-text" style={{ flexDirection: this.props.flexDirection, height: this.props.height, transform: this.state.textTrasform }}>
                            <div className="animated slideInLeft">
                                <h1 style={{ margin: 'auto', marginTop: this.props.marginTop, textAlign: this.props.align }}>{this.props.title}</h1>
                            </div>
                            <div className="header-content animated slideInRight" style={{ textAlign: this.props.align }}>
                                {this.props.content}
                            </div>
                        </div>
                    </ScrollAnimation>

                </div>
            </div>
        )
    }
}