import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import '../GridItem/GridItem'
import '../../App.css'
import './Home.css'
import GridItem from '../GridItem/GridItem'

import client from "../../client"
import imageUrlBuilder from '@sanity/image-url'
import moment from 'moment';

import "animate.css/animate.min.css";

/**
 *  Home page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // Header overlay, the opacity will be updated on scroll
            overlay: 'linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0))',
            // Stores data on the most recent post
            recentPost: {},
            postImage: "",
            postUrl: ""
        }

        this.urlFor = this.urlFor.bind(this)
        this.handleScroll = this.handleScroll.bind(this)

    }

    /**
     * Generate image urls from Sanity image records.
     * @param {string} source 
     * @see {@link https://www.sanity.io/docs/image-url} for more information
     */
    urlFor(source) {
        return imageUrlBuilder(client).image(source)
    }

    componentDidMount() {
        // Listens for scrolling...
        window.addEventListener('scroll', this.handleScroll);

        let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'

        // Fetch the most recent Journal post by getting the first item of a list of posts ordered by publish date
        // excludes post on Opportnities
        client.fetch(`*[_type == "post" && category._ref != "${opportunitiesID}"] | order(publishedAt desc)`)
            .then(data => {
                this.setState({
                    recentPost: data[0],
                    postImage: this.urlFor(data[0].mainImage.asset._ref),
                    postUrl: data[0].slug.current
                })
            })
            .catch(err => console.log(err))

    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    /**
     *  Trigger micro-interactions on scroll
     */
    handleScroll() {
        let scrollTop = document.documentElement.scrollTop
        let overlayOpacity;

        // Opacity of overlay increases as we scroll down, hence the opacity value is set relative to the scroll position
        overlayOpacity = (scrollTop/1000)*1.3

        // Updates opacity of the header overlay
        this.setState({
            overlay: `linear-gradient(to right, rgba(0,0,0,${0.2 + overlayOpacity}), rgba(0,0,0,${overlayOpacity}))`
        });
    }

    render() {
        let recentPost = this.state.recentPost;
        let postImage = this.state.postImage;
        let postUrl = this.state.postUrl;

        // let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'
        
        return(
            <div>
                <div id="home-header-container">
                    <div id="home-header-overlay" style={{background: this.state.overlay}}>
                       <div className="home-header-text">
                           <h1 className="animated slideInDown">Cultural Translation for the International Market</h1>
                           <h3 className="animated slideInUp">Localization. Cultural Naturalization. Production Management. Talent.</h3>
                        </div> 
                    </div>
                </div>
                
                <div className="main-container" id="home-container">
                    <div className="main-content">
                        <div className="grid-container">
                            <div id="what-we-do-box">
                                <Link to={"/services"}>
                                    <GridItem 
                                        title="What we do"
                                        content="Dubbing, Translation and Cultural Naturalization services for all projects large and small. We also provide creative talent, technical talent and end to end production management to fill the gaps in your creative projects. 
                                        Our centralized processes mean you deal with one company – us – for all your localization needs."
                                        bg="mixins.jpg"
                                        bgColor="rgba(255, 147, 31,0.92)"
                                    />
                                </Link>
                            </div>
                            
                            <div id="who-we-are-box">
                                <Link to={"/about-us"}>
                                    <GridItem 
                                        title="Our story"
                                        content="Since 2014, Sleigh Group has brought quality and synchresis to the biggest franchises in entertainment, growing from a small local player, to a truly international company with offices in Australia and the Philippines, as well as a presence in Indonesia and the United Kingdom."
                                        bg="who-we-are.jpg"
                                        bgColor="rgba(70, 103, 224, 0.9)"
                                    />
                                </Link>
                            </div>

                            <div id="platform-beta">
                                <Link to={"/platform-beta-eoi"}>
                                    <GridItem 
                                        title="Platform Beta Signup"
                                        content="Sign up for the Sleigh Platform Beta trial."
                                        bg="platform.png"
                                        bgColor="rgba(40, 47, 72, 0.8)"
                                    />
                                </Link>
                            </div>
                            
                            <div id="journal-box">
                                <Link to={`/journal/${postUrl}`}>
                                    <GridItem 
                                        title="Journal"
                                        journalHeader={recentPost.title}
                                        journalDate={moment(recentPost.publishedAt).format('LL')}
                                        content={<p>{recentPost.textPreview}</p>}
                                        image={postImage}
                                        url={postUrl}
                                        bg="blank-landscape.jpg"
                                        bgColor="rgba(0,0,0,0.9)"
                                        isJournal = {true}
                                    />
                               </Link> 
                            </div>
                            
                            <div id="our-work-box">
                                <a href={`mailto:contact@sleigh.group`}>
                                    <GridItem 
                                        title="Our work"
                                        content={
                                            <span>Please <b>contact us</b> if you would like to see samples of our previous work.</span>}
                                        bg="blank-landscape.jpg"
                                        bgColor="linear-gradient(275deg, #ff5554, #cb1670)"
                                        textClass= "show-text-on-mobile"
                                    />
                                </a>
                            </div>
                            
                            <div id="opportunities-box">                               
                                <Link to='/opportunities'>
                                    <GridItem 
                                        title="Opportunities"
                                        content="Come work with us."
                                        bg="blank-landscape.jpg"
                                        bgColor="linear-gradient(155deg, rgb(120, 135, 232), rgb(135, 206, 235))"
                                    />
                                </Link>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}