import React, { Component } from 'react'

import Header from '../Header/Header'
import Block from "../Block/Block"

import './About.css'

/**
 *  About page component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class About extends Component {
    
    render() {
        // Paragraph on header
        let headerContent =
            <div>
                <p>
                    Established in 2014, Sleigh Group grew from Synchresis, a dedicated localization company, 
                    into a technologically focused language services company providing technology solutions 
                    that simplify the production of localized media. With over forty years combined experience 
                    in the localization industry, Sleigh’s founders have built the company into a trusted provider 
                    servicing some of the biggest names in global entertainment.
                </p>
            </div>

        return(
            <div>
                <Header 
                    pageName="About us" 
                    bgColor="linear-gradient(145deg, #5680E9, #84CEEB) 0% 0% / 300% 300%" 
                    bgImage="audio-1844798_1920.jpg"
                    title="Our Story"
                    content={headerContent}
                    marginTop="80px"
                />
                
                <div className="main-container" id="about-container">
                    <div className="main-content">
                        <h1 style={{color: 'rgb(0,0,128)'}}>Our People</h1>
                        
                        <div className="grid-container">
                            <div id="ivy" className="about-block">
                                <Block 
                                    title="Ivy Novacco"
                                    subtitle="Founder / CEO"
                                    image="headshot/ivy-2.jpg"
                                    secondaryImage="headshot/ivy-2.jpg"
                                    flexDirection= "row"
                                    content={
                                        <div>
                                            <p>
                                                After successful careers in Process Management, Process Development and 
                                                Cultural Training with Dell and HP, Ivy returned to her voice acting roots 
                                                to build Sleigh Group. She utilizes her financial expertise and business 
                                                acumen in conjunction with her 20 odd years of experience in the entertainment 
                                                industry to build the future of entertainment.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                            
                            <div id="cat" className="about-block">
                                <Block 
                                    title="Catherine Lopes"
                                    subtitle="Founder / CTO"
                                    image="headshot/cat-2.jpg"
                                    secondaryImage="headshot/cat-2.jpg"
                                    flexDirection= "row-reverse"
                                    content={
                                        <div>
                                            <p>
                                                With more than two decades of experience in data &amp; analytics, Dr. Lopes brings broad expertise 
                                                and experience in both technology and data analytics to Sleigh Group. She has worked in America, 
                                                Australia and China in both academia and industry across consulting, software development and technology 
                                                and data delivery. For the last decade, she has focused on helping organizations bridge the gap between 
                                                business and technology by using data governance and management to enable machine and artificial intelligence. 
                                                At Sleigh Group, Dr. Lopes spearheads the efforts in applying technological solutions to the entertainment production 
                                                industry’s pain points.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            <div id="goffy" className="about-block">
                                <Block 
                                    title="Andrew Goff"
                                    subtitle="CFO"
                                    image="headshot/AndrewGoff.jpg"
                                    secondaryImage="headshot/Rudolf-2.JPG"
                                    flexDirection= "row"
                                    content={
                                        <div>
                                            <p>
                                                Andrew’s passion for businesses which are sustainable and purposeful as well as profitable 
                                                made him a good fit for Sleigh Group. His professional contributions to causes like marriage 
                                                equality, ending modern slavery and anti-racism makes him a great one. Previously Andrew has 
                                                worked in scale-ups and “intrapreneurship”, helping to launch .blog, contribute to the success 
                                                of partnership programs in financial services, and working on data analytics innovations that 
                                                led to self-install broadband internet in Australia. His experience in remote work 
                                                environments—most notably for Automatic—has helped Sleigh Group navigate the challenges of 
                                                COVID-19 and ready us to seize the global opportunities ahead.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}