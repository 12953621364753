import React, {Component} from 'react';
import './Button.css'

/**
 *  Button component - the style is defined in Button.scss by class name 
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Button extends Component {
    render() {
        return(
            // Class name passed as props determine the style of the button
            <button id="btn" className={this.props.buttonClass} style={{width: this.props.width, margin: this.props.margin, height: this.props.height}}>
                {this.props.buttonText}
            </button>
        )
    }
}