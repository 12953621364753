import React, { Component } from 'react'
import Header from '../Header/Header'

/**
 *  Not Found page component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
class NotFound extends Component {
    render() {
        return(        
            <Header 
                pageName="Not Found" 
                bgColor="linear-gradient(230deg, #1e0b4d, #8660e6) 0% 0% / 300% 300%" 
                bgImage="blank-landscape.jpg"
                title="404: Page not found"
                flexDirection="column"
                marginTop="20%"
                align="center"
                height="100vh"
            />
        );
    }
}

export default NotFound;