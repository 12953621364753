import React, { Component } from 'react'

import '../Button/Button'
import './GridItem.css'

/**
 *  Grid Item component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class GridItem extends Component {

    render() {
        let figcaption, image;

        // Grid item content for journal preview
        if(this.props.isJournal && this.props.image !== undefined) {
            image = <div 
                    className="image" 
                    style={{
                        backgroundImage: `url(${this.props.image})`,
                        backgroundSize: 'cover',
                        height: "100%"}}>
                    </div>
            figcaption = <figcaption>
                            
                            <h4>{this.props.journalDate}</h4>
                            <h2>{this.props.journalHeader}</h2>
                            <div className="journalContent">{this.props.content}</div>
                            <p className="read-more">Read more &gt;</p>
                            
                        </figcaption>
        
        } 
        
        // Grid item content for any other grid panels
        else {
            image = <img className="image" src={require(`../../assets/${this.props.bg}`)} alt={this.props.title}></img>

            figcaption = <figcaption>
                            <h2>{this.props.title}</h2>
                            <p className={this.props.textClass}>{this.props.content}</p>
                        </figcaption>


        }

        return(
            <figure>
                {image}
                <div className="grid-item-overlay" style={{'background': `${this.props.bgColor}`}}></div>
                {figcaption}
            </figure>
        )
    }
}