import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Button from './components/Button/Button'
import Home from './components/Home/Home'
import About from './components/About/About'
import Services from './components/Services/Services'
import Journal from './components/Journal/Journal'
import ScrollToTop from './components/ScrollToTop'
import Article from './components/Article/Article'
import IframeForm from './components/IframeForm/IframeForm'
import NotFound from './components/NotFound/NotFound'
import Opportunities from './components/Opportunities/Opportunities'

import JournalStaging from './components/Journal/JournalStaging'
import ArticleStaging from './components/Article/ArticleStaging'


import ModalContainer from './components/ModalContainer/ModalContainer'
import Navbar from './components/Navbar/Navbar'

import './App.css';

/**
 *  App component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class App extends Component {
  constructor(props) {
    super(props)

    this.state={
      loginModal: ""
    }
  }

  /**
   * Opens Login Modal
   */
  openLoginModal = () => {
    let linkStyle = {display: "block", margin: '3px auto', width: "70%"}

    let loginContent = 
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: "space-around"}}>
      <a href="https://app.sleigh.group/login" style={linkStyle}>
        <Button buttonClass={"blue-button"} buttonText={"Client Login"} width="100%" height="50px"></Button>
      </a>
      <a href="https://app.sleigh.group/login" style={linkStyle}>
        <Button buttonClass={"blue-button"} buttonText={"Project Management Login"} width="100%" height="50px" margin="5px auto"></Button>
      </a>
      <Button buttonClass={"disabled-button"} buttonText={"Production Login"} width="70%" height="50px" margin="5px auto"></Button>
      <Button buttonClass={"disabled-button"} buttonText={"Talent Login"} width="70%" height="50px" margin="5px auto"></Button>
    </div>

    // Set loginModal state to ModalContainer Component to render modal
    this.setState({
      loginModal: <ModalContainer 
                    header="Login"
                    content={loginContent}
                    minWidth="300px"
                    onClose={this.closeLoginModal}
                  />
    })
  }

  /**
   * Closes Login Modal
   */
  closeLoginModal = () => {
    this.setState({
      loginModal: ""
    })
  }

  render() {

    return (
        <Router>
          
            <Navbar loginClickHandler={this.openLoginModal}/>

            {this.state.loginModal}

            {/* On route change, ScrollToTop makes sure that the view starts from the top of the page */}
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/about-us" component={About} />
                <Route path="/services" component={Services} />
                <Route exact path="/journal" component={Journal} />
                <Route path="/journal/:post" component={Article} />
                <Route exact path="/journal-staging/" component={JournalStaging} />
                <Route exact path="/journal-staging/:post" component={ArticleStaging} />
                <Route exact path="/opportunities" component={Opportunities} />
                <Route path="/opportunities/:post" component={Article} />
                <Route path="/inquire">
                  <IframeForm iframe='<iframe src="https://services.cognitoforms.com/f/t9o-8CFsP0qOlWtp0D9pyw?id=24" style="position:relative;width:1px;min-width:100%;*width:100%;" frameborder="0" scrolling="yes" seamless="seamless" height="895" width="100%"></iframe>'/>
                </Route>
                <Route path="/platform-beta-eoi">
                  <IframeForm iframe='<iframe src="https://www.cognitoforms.com/f/t9o-8CFsP0qOlWtp0D9pyw?id=26" style="position:relative;width:1px;min-width:100%;*width:100%;" frameborder="0" scrolling="yes" seamless="seamless" height="665" width="100%"></iframe>'/>
                </Route>
                <Route path="/mobile-recording-kit-eoi">
                  <IframeForm iframe='<iframe src="https://www.cognitoforms.com/f/t9o-8CFsP0qOlWtp0D9pyw?id=27" style="position:relative;width:1px;min-width:100%;*width:100%;" frameborder="0" scrolling="yes" seamless="seamless" height="609" width="100%"></iframe>'/>
                </Route>
                <Route component={NotFound} />
            </Switch>
            <Route path="/login"> </Route>
            <footer>
              <p><b>Contact us</b> at <a style={{color: 'white', textDecoration: 'underline'}} href="mailto:contact@sleigh.group">contact@sleigh.group</a></p>
              <p style={{opacity: '0.8', fontSize: '0.9em'}}>&copy; 2020 Sleigh Group – All rights reserved</p>
            </footer>
        </Router>
    );
  }
}

