import React, { Component } from 'react'
import '../Card/Card.css'

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

/**
 *  Card component is a flex container consisting of text and image
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class Card extends Component {

    render() {
        let cardHeight;

        // Only set the full height if the card's flex direction is column
        if(this.props.direction !== "row") {
            cardHeight= "100%"
        }

        return(
            <div className="card-container" style={{flexDirection: this.props.direction, height: cardHeight}}>
        
                <div 
                    className="card-img" 
                    style={{
                        background: `url(${require(`../../assets/${this.props.backgroundImage}`)}) no-repeat`,
                        backgroundSize: 'cover',
                        height: this.props.imageHeight}}>
                </div>
                
                <div className="card-text" style={{padding: this.props.padding}}>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        <h2>{this.props.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        {this.props.content}
                    </ScrollAnimation>
                </div>
            </div>
        )
    }
}