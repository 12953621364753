import React, { Component } from 'react'
import { Link } from "react-router-dom";

import Header from "../Header/Header"
import Card from "../Card/Card"

import "./Services.css"

/**
 *  Services page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Services extends Component {
    constructor(props) {
        super(props)

        this.state = { 
            width: 0, 
            height: 0,
            flexDirection: "",
            imageHeight: "",
            cardPadding: "",
            headerContent: ""
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        this.updateWindowDimensions();

        // Listens for window resize...
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    /**
     * Handle window resize trigger
     */
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });

        // Update contet and styles based on screen width
        if(window.innerWidth >= 768) {
            this.setState({
                flexDirection: "row",
                imageHeight: "inherit",
                cardPadding: "5%",
                headerContent: 
                    <div>
                        <p>
                            Sleigh Group provides the highest quality cultural translation in the localization market, going well beyond standard 
                            translation services. We ensure every joke, reference and cultural nuance are as easily digestible and immediate in our 
                            translations as they were in the original. Our dedication to quality and efficiency has made us one of the preferred 
                            localization providers in Asia, handling productions of A-list movies and television shows for the biggest names in 
                            entertainment. 
                        </p>
                        <p>
                            We also take the hassle out of dealing with multiple companies for multiple language translations. Our streamlined 
                            technology project management interface manages your project from end to end and takes the busywork out of the equation. 
                        </p>
                        <p>
                            Whether you have five minutes or five hundred hours of content, we work with you to ensure you receive the top tier quality 
                            and care that Sleigh Group is known for throughout the industry.
                        </p>
                    </div>
            })
        } else {
            this.setState({
                flexDirection: "column",
                imageHeight: "300px",
                cardPadding: "5% 10%",
                headerContent:
                    <div>
                        <p>
                            Dubbing, Translation and Cultural Naturalization services for all projects large and small. We also provide creative talent, 
                            technical talent and end to end production management to fill the gaps in your creative projects.
                        </p>
                        <p>
                            Whether you have five minutes or five hundred hours of content, we work with you to ensure you receive the top tier quality 
                            and care that Sleigh Group is known for throughout the industry. Our centralized processes mean you deal withone company – 
                            us – for all your localization needs.
                        </p>
                    </div> 
                    
                
            })
        }
    }

    render() {

        return(
            <div>
                <Header 
                    pageName="Services" 
                    bgColor="linear-gradient(140deg, #d12d4c, #ffaf24) 0% 0% / 300% 300%" 
                    bgImage="audio-1844798_1920.jpg"
                    title="What we do"
                    content={this.state.headerContent}
                />

                <div className="slanted-box"></div>

                <div className="main-container" id="services-container">
                    <div className="main-content">
                        <h1 style={{color: 'rgb(0,0,128)'}}>Our Services</h1>
                        <div className="grid-container">
                            <div id="dubbing-card" className="service-card">
                                <Card 
                                    title="Dubbing and Voice Over Production"
                                    content={
                                        <div>
                                            <p>
                                                Our international talent pool gets you access to skilled and certified voice actors with experience in dubbing 
                                                a range of television series and movies, as well as audio description and audio for original content and commercials.
                                            </p>
                                            <p>
                                                We invest heavily in our voice actors, providing training in areas such as vocal manipulation and mimicry, accents 
                                                and synchronization. When combined with our production and project management expertise, we make your audience forget 
                                                they’re watching a dubbed film—if they even notice it at all. <Link to={"/inquire"} style={{color: 'rgb(0,0,128)'}}>Send us a query</Link> to get started.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage="Recording06.jpg"
                                    direction="column"
                                />
                            </div>
                            <div id="translation-card" className="service-card">
                                <Card 
                                    title="Translation and Subtitling"
                                    content={
                                        <div>
                                            <p>
                                                Our scriptwriters champion cultural naturalization, going beyond translating dialogue to ensuring that all 
                                                cultural specific nuances and slang are appropriately conveyed in our scriptwriting.
                                            </p>
                                            <p>
                                                We are masters of Tagalog and English translation, and confidently offer 11 languages as standard. 
                                                Other languages are available for specialized jobs and we are increasing support for additional languages 
                                                all the time. If you’d like to ask about a specific language, please <Link to={"/inquire"} style={{color: 'rgb(0,0,128)'}}>send us a query</Link>.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage="translate.jpg"
                                    direction="column"
                                />
                            </div>
                            <div id="production-management-card" className="service-card">
                                <Card 
                                    title="Production Management"
                                    content={
                                        <div>
                                            <p>
                                                We provide all of our clients with complimentary access to our specialised project management interface, 
                                                and back it up with experienced project managers and coordinators. We streamline the entire post-production 
                                                and localization process, from ordering, juggling multiple localization language orders, to automating paperwork 
                                                and invoicing. We also offer personalized support to ensure any issues are dealt with promptly and smoothly.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage="project-dashboard.jpg"
                                    // imageHeight= {this.state.imageHeight}
                                    direction="column"
                                    // padding= {this.state.cardPadding}
                                />
                            </div>
                            <div id="talent-card" className="service-card">
                                <Card 
                                    title="Creative and Production Talent"
                                    content={
                                        <div>
                                            <p>
                                                Our clients have access to our international pool of creative talent. In addition to voice actors, script writers and translators, 
                                                we have lyricists, musicians, dubbing and recording directors and original content creators all working together to deliver to the creative brief.
                                            </p>
                                            <p>
                                                We also offer the services of our expert technical and production staff. Whether you need audio engineers, mixing experts, project coordinators or something 
                                                a bit more specialised, we have you covered. Have something technical that needs completed quickly to a high standard? <Link to={"/inquire"} style={{color: 'rgb(0,0,128)'}}>Send us a query today</Link>, and tell us how we can help.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage="audio-console-2723867_1920.jpg"
                                    direction="column"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}