import React, { Component } from 'react'
import { Link } from "react-router-dom";

import client from "../../client"
import imageUrlBuilder from '@sanity/image-url'
import groq from 'groq'
import BlockContent from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import Vimeo from '@u-wave/react-vimeo';
import moment from 'moment';


import "../Header/Header.css"
import "./Article.css"

/**
 *  Article page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Article slug fetched by getting the parameters in the url - the slug is used to query the specific article/post
            slug: props.match.params.post,
            // Where the data of the article/post is stored
            post: {},
            postType: ""
        }

        this.urlFor = this.urlFor.bind(this)
    }

    
    componentDidMount() {
        // Use Sanity's query laguage (GROQ) to query the selected post from Sanity's data store
        const query = groq`*[_type == "post" && slug.current == "${this.state.slug}"][0]
        {title, body, mainImage, publishedAt, attachmentTitle, fileAttachment, "attachmentURL": fileAttachment.asset->url, "category": category->title, "author": author->name, "authorImage": author->image}`
        
        // Fetch data on the article, storing it on this.state.post so that the page re-renders      
        client.fetch(query)
            .then(data => {
                this.setState({post: data})
            })
            .catch(err => console.log(err))

        // Get currect post type (Journal or Opportunities) through the location pathname
        let currectPostType = window.location.pathname
        let processedPostType =currectPostType
                                .replace(this.state.slug, '')
                                .replace('/','')
                                .replace('/','')
                                .replace(currectPostType.charAt(1), currectPostType.charAt(1).toUpperCase())
        this.setState({postType: processedPostType})
        

    }

    
    
    /**
     * Generate image urls from Sanity image records.
     * @param {string} source 
     * @see {@link https://www.sanity.io/docs/image-url} for more information
     */
    urlFor(source) {
        return imageUrlBuilder(client).image(source)
    }

    render() {
        let parentDirectory = '/' + this.state.postType

        let article = this.state.post;
        let image, authorImage, attachmentTitle = "Downloadable File";
        let filesContainer;

        // Make sure the article data and repective images and files exist to avoid undefined errors before setting variables
        if(article.mainImage !== undefined) {
            image = article.mainImage.asset._ref
        }

        if(article.authorImage !== undefined) {
            authorImage = article.authorImage.asset._ref
        }

        if(article.attachmentTitle !== undefined) {
            attachmentTitle = article.attachmentTitle
        } 

        // Only show files if there is a file uploaded
        if(article.attachmentURL !== undefined) { 
            filesContainer = 
            <div>
                <h4>Files</h4>
                <p><a href={`${article.attachmentURL}?dl=`}>{attachmentTitle}</a></p>
            </div>
        } else {
            filesContainer = <div></div>
        }

        const BlockRenderer = props => {
            const {style = 'normal'} = props.node;
      
            if (/^h\d/.test(style)) {
                const level = style.replace(/[^\d]/g, '')
                return React.createElement(style, { className: `heading-${level}`}, props.children)
            }
            
            if (style === 'blockquote') {
                return <blockquote className="quote"> {props.children}</blockquote>
            }
            
            // Fall back to default handling
            return BlockContent.defaultSerializers.types.block(props)
          }

        // Specifies the React components to use for rendering content
        const serializers = {
            // Youtube and Vimeo embed
            types: {
                youtube: ({node}) => {
                  const { url } = node
                  const id = getYouTubeId(url)
                  return (<YouTube videoId={id} />)
                },
            
                vimeo: ({node}) => {
                const { url } = node
                return (<Vimeo video={url} width="650"/>)
                },
                block: BlockRenderer
              },
            marks: {
              // Settings for links to CMS items, the link should open the page of the cms item
              internalLink: ({mark, children}) => {
                const {slug = {}} = mark
                const href = `/${slug.current}`
                return <a href={href}>{children}</a>
              },
              link: ({mark, children}) => {
                const { blank, href } = mark
                return blank ?
                  <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
                  : <a href={href}>{children}</a>
              }
            }
          }
          
        return(
            <div className="main-container article-container">
                <div className="breadcrumbs">
                    
                    <Link to={parentDirectory} style={{ textDecoration: 'none', color: "black"}}>
                        &lt; Back to {this.state.postType}
                    </Link> 
                </div>
                <div className="main-content">
                    <div className="article-header">
                        <h4>{moment(article.publishedAt).format('LL')}</h4>
                        <h1>{article.title}</h1>
                        <div className="author-details">
                            By {article.author}
                        </div>
                    </div>
                    
                    {/* <div className="article-image"
                    style={{
                        backgroundImage: `url(${this.urlFor(image)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                    </div>     */}
                    <img src={`${this.urlFor(image)}`} className="article-image"></img>

                    <div className="article-content">
                        <BlockContent
                            blocks={article.body}
                            serializers={serializers}
                            // imageOptions={{ w: 320, h: 240, fit: 'max' }}
                            {...client.config()}
                        />

                        {filesContainer}
                    </div>
                </div>
            </div>
        )
    }
}