import React, { Component } from 'react'

import './ModalContainer.css'

/**
 *  Modal Container component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class ModalContainer extends Component {
    render() {
        return(
            <div className="modal-overlay">
                <div className="modal-container" style={{minWidth: this.props.minWidth}}>
                    <div className="exit-modal" onClick={this.props.onClose}>
                        Close
                    </div>
                    <h1>{this.props.header}</h1>
                    <div className="modal-content">
                        {this.props.content}
                    </div> 
                </div>
                
            </div>
        )
    }
}