import React, { Component } from 'react'

import './Block.css'

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

/**
 *  Block component - A block of flex container containing text and images
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class Block extends Component {
    render() {
        return(
            <div className="block-container" style={{flexDirection: this.props.flexDirection}}>
                
                <div className="block-img">
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                        <img src={require(`../../assets/${this.props.image}`)} alt={this.props.title}></img>
                    </ScrollAnimation>
                </div>  
                
                <div className="block-text">
                    <ScrollAnimation animateIn="fadeInRight" animateOnce>
                        <h4>{this.props.subtitle}</h4>
                        <h2>{this.props.title}</h2>
                        <div className="block-content">
                            {this.props.content}
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        )
    }
}