import React, { Component } from 'react'

import "./IframeForm.css"
import "animate.css/animate.min.css";

/**
 *  Inquiry form component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class IframeForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true
        }

        this.iframe = this.iframe.bind(this)
    }
    
    componentDidMount() {
        let form = document.getElementsByTagName('iframe')[0]

        form.addEventListener('load', () => {
            this.setState({isLoading: false})
        })
    }

    /**
     * Create iframe element to render
     * @param {*} iframe 
     */
    iframe(iframe) {
      return {
        __html: iframe
      }
    }

    render() {
        
        let loader;

       // Show loader while form is loaded
        if(this.state.isLoading) {
            loader = <div className="loading animated pulse infinite slower"><h3>Loading...</h3></div>
        } else {
            loader = <div></div>
        }

        return(
            <div className="main-container" id="inquiry-container">
                <div className="main-content">
                    {loader}
                    <div dangerouslySetInnerHTML={this.iframe(this.props.iframe)}></div>
                </div>
            </div>
        )
    }
}